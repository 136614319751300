import React, { forwardRef, useRef, useState } from 'react'
import Reaptcha, { ReaptchaProps } from 'reaptcha'

export const useCaptcha = () => {
    const ref = useRef<Reaptcha>(null)
    const [verified, setVerified] = useState(false)

    const verify = () => setVerified(true)
    const expire = () => setVerified(false)
    const reset = () => ref.current?.reset()

    return {
        ReCAPTCHA,
        ref,
        verified,
        verify,
        expire,
        reset,
    }
}

const ReCAPTCHA = forwardRef<Reaptcha, Omit<ReaptchaProps, 'sitekey'>>(
    (props, ref) => (
        <Reaptcha
            ref={ref}
            {...props}
            sitekey="6Ley6hYcAAAAALm0jG1qSvtSlQQSkCzVHNJkgNwt"
        />
    )
)

ReCAPTCHA.displayName = 'ReCAPTCHA'
