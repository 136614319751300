import React from 'react'
import {
    Layout,
    SEO,
    PageTitle,
    useTranslations,
    LocaleConfig,
    usePageSections,
    SectionSpacer,
} from '../modules/core'
import { ContactForm } from '../modules/contact'
import { graphql } from 'gatsby'

export interface ContactPageData {
    allMarkdownRemark: {
        edges: Array<{
            node: {
                frontmatter: {
                    section: 'contactForm'
                    title: string
                    locale: LocaleConfig['locale']
                }
                html: string
            }
        }>
    }
}

export const query = graphql`
    query ContactQuery($locale: String) {
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    page: { eq: "contact" }
                    locale: { eq: $locale }
                }
            }
        ) {
            edges {
                node {
                    frontmatter {
                        section
                        title
                    }
                    html
                }
            }
        }
    }
`

interface ContactPageProps {
    path: string
    pageContext: {
        localeConfig: LocaleConfig
    }
    data: ContactPageData
}

const ContactPage = ({ path, pageContext, data }: ContactPageProps) => {
    const { translate } = useTranslations(pageContext.localeConfig.locale)
    const sections = usePageSections<'contactForm'>(data)

    return (
        <Layout
            locale={pageContext.localeConfig}
            path={path}
            hasContactSection={false}
        >
            <SEO title={translate('page_contact')} />
            <PageTitle>{translate('page_contact')}</PageTitle>
            <ContactForm
                title={sections.contactForm.title}
                privacyPolicy={sections.contactForm.content}
                locale={pageContext.localeConfig}
            />
            <SectionSpacer />
        </Layout>
    )
}

export default ContactPage
