import React, { FormEvent, useCallback, useRef } from 'react'
import styled from 'styled-components'
import toast, { Toaster } from 'react-hot-toast'
import { LocaleConfig, useTranslations, Container, submitForm } from '../core'
import { useCaptcha } from '../core/useCaptcha'

interface ContactFormProps {
    title: string
    privacyPolicy: string
    locale: LocaleConfig
}

interface FormElements extends HTMLFormControlsCollection {
    first_name: HTMLInputElement
    last_name: HTMLInputElement
    furigana: HTMLInputElement
    email: HTMLInputElement
    company: HTMLInputElement
    department: HTMLInputElement
    occupation: HTMLInputElement
    phone: HTMLInputElement
    inquiry: HTMLInputElement
}
interface ContactFormElement extends HTMLFormElement {
    readonly elements: FormElements
}

export const ContactForm = ({
    title,
    privacyPolicy,
    locale,
}: ContactFormProps) => {
    const { translate } = useTranslations(locale.locale)
    const form = useRef<HTMLFormElement>(null)

    const {
        ReCAPTCHA,
        ref: reCAPTCHARef,
        verified,
        verify,
        expire,
        reset: resetCaptcha,
    } = useCaptcha()

    const handleReset = useCallback(() => {
        form.current?.reset()
        expire()
        resetCaptcha()
    }, [expire, resetCaptcha])

    const handleSubmit = useCallback(
        (event: FormEvent<ContactFormElement>) => {
            event.preventDefault()

            if (!verified) {
                return
            }

            toast.promise(
                submitForm({
                    first_name: event.currentTarget.elements.first_name.value,
                    last_name: event.currentTarget.elements.last_name.value,
                    furigana: event.currentTarget.elements.furigana.value,
                    email: event.currentTarget.elements.email.value,
                    company: event.currentTarget.elements.company.value,
                    department: event.currentTarget.elements.department.value,
                    occupation: event.currentTarget.elements.occupation.value,
                    phone: event.currentTarget.elements.phone.value,
                    inquiry: event.currentTarget.elements.inquiry.value,
                })
                    .then(handleReset)
                    // We want to rethrow the error so that the toaster reacts to it
                    .catch((error) => {
                        throw error
                    }),
                {
                    loading: translate('form_submitting'),
                    success: translate('form_success'),
                    error: translate('form_error'),
                }
            )
        },
        [verified, handleReset, translate]
    )

    return (
        <>
            <Container>
                <Contact>
                    <Title>{title}</Title>
                    <Form ref={form} onSubmit={handleSubmit}>
                        <FormRow>
                            <Label htmlFor="last_name" required>
                                {translate('form_name')}
                            </Label>
                            <TextInput
                                id="last_name"
                                placeholder={translate('form_lastname')}
                                required
                            />
                            <TextInput
                                id="first_name"
                                placeholder={translate('form_firstname')}
                                required
                            />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor="furigana">
                                {translate('form_furigana')}
                            </Label>
                            <TextInput id="furigana" />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor="email" required>
                                {translate('form_email')}
                            </Label>
                            <EmailInput id="email" required />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor="company" required>
                                {translate('form_company')}
                            </Label>
                            <TextInput id="company" required />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor="department" required>
                                {translate('form_department')}
                            </Label>
                            <TextInput id="department" required />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor="occupation">
                                {translate('form_occupation')}
                            </Label>
                            <TextInput id="occupation" />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor="phone" required>
                                {translate('form_phone')}
                            </Label>
                            <TextInput id="phone" required />
                        </FormRow>
                        <FormRow>
                            <Label htmlFor="inquiry" required>
                                {translate('form_inquiry')}
                            </Label>
                            <TextArea id="inquiry" required />
                        </FormRow>
                        <PrivacyPolicyFormRow>
                            <PrivacyPolicy
                                dangerouslySetInnerHTML={{
                                    __html: privacyPolicy,
                                }}
                            />
                            <PrivacyPolicyCheckboxWrapper>
                                <Checkbox id="terms" required />
                                <Label htmlFor="terms" required>
                                    {translate('form_terms')}
                                </Label>
                            </PrivacyPolicyCheckboxWrapper>
                        </PrivacyPolicyFormRow>
                        <SubmitWrapper>
                            <ReCAPTCHA
                                ref={reCAPTCHARef}
                                onVerify={verify}
                                onError={expire}
                                onExpire={expire}
                            />
                            <SubmitButton disabled={!verified} type="submit">
                                {translate('form_submit')}
                            </SubmitButton>
                        </SubmitWrapper>
                    </Form>
                </Contact>
            </Container>
            <Toaster
                containerStyle={{
                    top: 112,
                }}
            />
        </>
    )
}

const Contact = styled.div``

const Title = styled.h3`
    @media ${({ theme }) => theme.custom_breakpoints.form} {
        font-size: 14px;
        line-height: 21px;
    }
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
`

const FormRow = styled.div`
    display: flex;
    width: 100%;

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        flex-direction: column;
        margin-bottom: 16px;
    }
`

const Label = styled.label<{ required?: boolean }>`
    width: 200px;
    margin-right: 48px;
    font-size: 14px;
    line-height: 21px;
    padding-top: 20px;

    &:after {
        content: '${({ required }) => (required ? '※' : '')}';
    }

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        width: auto;
        padding-top: 0;
        margin-bottom: 12px;
    }
`

const TextInput = styled.input.attrs((props) => ({
    type: props.type === undefined ? 'text' : props.type,
    maxLength: 255,
}))`
    font-size: 16px;
    padding: 0 30px;
    height: 58px;
    flex: 1 1 auto;
    box-shadow: 0 0 0 10px ${({ theme }) => theme.colors.accent};
    margin: 0 12px 12px 0;
    border: 0;
    -webkit-appearance: none;
    border-radius: 0;

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        width: calc(100% - 10px);
        box-shadow: 0 0 0 5px ${({ theme }) => theme.colors.accent};
        padding: 10px;
        margin: -2px 7px 7px 5px;
        height: 48px;
    }
`

const EmailInput = styled(TextInput).attrs({ type: 'email' })``

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
    width: 22px;
    height: 22px;
    margin-right: 15px;
`

const TextArea = styled.textarea.attrs({ maxLength: 65535 })`
    flex: 1 1 auto;
    box-shadow: 0 0 0 10px ${({ theme }) => theme.colors.accent};
    height: 248px;
    padding: 30px;
    margin: 0 12px 12px 0;
    border: 0;
    -webkit-appearance: none;
    border-radius: 0;

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        width: calc(100% - 10px);
        box-shadow: 0 0 0 5px ${({ theme }) => theme.colors.accent};
        padding: 10px;
        margin: -2px 7px 7px 5px;
    }
`

const PrivacyPolicyFormRow = styled(FormRow)`
    flex-direction: column;
    width: calc(100% - 240px);
    background: ${({ theme }) => theme.colors.accent};
    padding: 10px;
    margin: -10px 2px 24px auto;

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        width: 100%;
        margin: 0 0 14px 0;
        padding: 5px;
    }
`

const PrivacyPolicy = styled.div`
    background: white;
    padding: 30px;
    font-size: 14px;
    line-height: 28px;

    p:first-child,
    p:last-child {
        margin-top: 0;
    }

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        padding: 10px;
    }
`

const PrivacyPolicyCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 30px 30px 20px 30px;

    ${Label} {
        width: 100%;
        padding: 0;

        @media ${({ theme }) => theme.custom_breakpoints.form} {
            margin: 0;
        }
    }

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        padding: 10px;
    }
`

const SubmitWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 240px);
    margin: -10px 2px 24px auto;

    @media ${({ theme }) => theme.custom_breakpoints.form} {
        width: 100%;
        margin: 0;
    }
`

const SubmitButton = styled.button`
    height: 52px;
    width: 220px;
    background-color: ${({ theme }) => theme.colors.invertedBackground};
    color: ${({ theme }) => theme.colors.invertedText};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 26px;
    border: 2px solid ${({ theme }) => theme.colors.invertedBackground};
    cursor: pointer;
    margin-top: 16px;
    user-select: none;

    &:disabled {
        background-color: ${({ theme }) => theme.colors.lightBackground};
        border-color: ${({ theme }) => theme.colors.lightBackground};
        color: ${({ theme }) => theme.colors.mutedText};
        cursor: not-allowed;

        @media (hover: hover) {
            &:hover {
                color: ${({ theme }) => theme.colors.mutedText};
            }
        }
    }

    @media (hover: hover) {
        &:hover {
            background-color: transparent;
            color: ${({ theme }) => theme.colors.text};
        }
    }
`
